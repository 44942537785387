import { useCurrencyContext } from '@contexts/currency-context';
import useMinimumPrice from '@hooks/useMinimumPrice';
import { TicketGroup } from '@interfaces/ticketevolution/tikets';
import { useBuildPackagesStore } from '@store/build-package-store';
import { formatCurrencyV2 } from '@utils/format-currency-v2';
import { ScrollArea, ScrollBar, Skeleton, Typography } from '@v2/ui';
import { cn } from '@v2/utils';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { useTicketsStore } from 'src/stores/tickets-store';

type Props = {
  list: TicketGroup[];
  loading: boolean;
};

export const findUniqueSectionNames = (arr: TicketGroup[]) => {
  const sectionsArray: string[] = [];
  arr.forEach(({ section }) => sectionsArray.push(section));
  return [...new Set(sectionsArray)];
};

export function TicketList({ list, loading }: Props) {
  const isOnlyElectronic = useTicketsStore((state) => state.isOnlyElectronic);
  const selectedTicketGroups = useTicketsStore((state) => state.selectedGroups);
  const ticketsQuantity = useTicketsStore((state) => state.ticketsQuantity);
  const [min, max] = useTicketsStore((state) => state.priceRange);

  const { ticketPrice: minTicketPrice, priceLoading } = useMinimumPrice(
    [],
    list,
    ticketsQuantity
  );

  const itemsFiltered = useMemo(() => {
    if (!list.length) return [];
    // If no sections are selected, show all tickets
    if (selectedTicketGroups.length === 0) {
      return list.filter((item) => {
        const { retail_price, quantity, eticket: Eticket, splits } = item;

        return (
          retail_price >= min &&
          retail_price * ticketsQuantity <= max &&
          quantity >= ticketsQuantity &&
          ((isOnlyElectronic && Eticket) || !isOnlyElectronic) &&
          splits.includes(ticketsQuantity)
        );
      });
    }

    // Only filter by sections if sections are selected
    const selectedTicketsByGroups = list.filter(({ tevo_section_name }) => {
      return selectedTicketGroups.includes(tevo_section_name);
    });

    const uniqueSections = findUniqueSectionNames(selectedTicketsByGroups);

    return list.filter((item) => {
      const {
        retail_price,
        section,
        quantity,
        eticket: Eticket,
        splits,
      } = item;

      return (
        retail_price >= min &&
        retail_price * ticketsQuantity <= max &&
        quantity >= ticketsQuantity &&
        uniqueSections.includes(section) &&
        ((isOnlyElectronic && Eticket) || !isOnlyElectronic) &&
        splits.includes(ticketsQuantity)
      );
    });
  }, [isOnlyElectronic, selectedTicketGroups, min, max, ticketsQuantity, list]);
  const ticketSelected = useTicketsStore((state) => state.ticketSelected);

  return (
    <ScrollArea className="grid h-[63svh] flex-col p-4 pt-0 md:h-[440px] md:p-6 md:pt-0">
      <div className="space-y-2 md:space-y-3">
        {loading &&
          [0, 1, 2, 3, 4, 5].map((item) => (
            <Skeleton key={item} className="h-[94px] w-full" />
          ))}

        {!loading && !itemsFiltered.length && (
          <Typography
            size="tmd"
            className="text-center font-medium text-gray-500"
          >
            Sorry, we couldn&apos;t find any seats that matched your search.
          </Typography>
        )}
        {!loading &&
          itemsFiltered?.length > 0 &&
          itemsFiltered.map((item, index) => (
            <TicketItem
              key={index}
              {...item}
              minTicketPrice={minTicketPrice}
              isItemSelected={ticketSelected?.id === item.id}
            />
          ))}
      </div>
      <ScrollBar />
    </ScrollArea>
  );
}

type TicketItemProps = TicketGroup & {
  minTicketPrice: number;
  isItemSelected: boolean;
};

function TicketItem(props: TicketItemProps) {
  const {
    signature,
    section,
    retail_price,
    available_quantity,
    row,
    minTicketPrice,
    isItemSelected,
  } = props;
  const setTicketSelected = useTicketsStore((state) => state.setTicketSelected);
  const ticketsQuantity = useTicketsStore((state) => state.ticketsQuantity);
  const { priceRate, currency } = useCurrencyContext();

  const isBundle = useBuildPackagesStore((state) => state.isBundle);

  const packagePrice =
    (retail_price * ticketsQuantity - (minTicketPrice ?? 0)) / ticketsQuantity;

  const finalPrice = isBundle ? packagePrice.toFixed(2) : retail_price;
  return (
    <div
      className={cn(
        `rounded-xl border-2 border-gray-200 bg-white p-5 ${isItemSelected ? 'border-2 border-primary-500' : ''}`
      )}
    >
      <button
        className="flex w-full items-center justify-between"
        onClick={() => {
          if (isItemSelected) {
            setTicketSelected(null);
            useTicketsStore.setState({ isTicketSelectionConfirmed: false });
          } else {
            useTicketsStore.setState({ isTicketSelectionConfirmed: true });
            setTicketSelected(props);
          }
        }}
      >
        <div className="flex flex-col gap-y-1 text-start">
          <h3 className="text-md font-b-old max-w-[15ch] truncate font-bold text-gray-800 md:max-w-[20ch] md:text-xl">
            Section {section}, Row {row}
          </h3>
          <span className="text-xs font-medium text-gray-500 md:text-sm">
            {available_quantity} {pluralize('ticket', available_quantity)}{' '}
            {signature && ` • ${signature}`}
          </span>
        </div>
        <div className="flex flex-col gap-y-1">
          <p className="text-md font-bold text-gray-800 md:text-xl">
            + {formatCurrencyV2(finalPrice, priceRate, currency, false, false)}
          </p>
          <span className="text-xs font-medium text-gray-500 md:text-sm">
            Per person
          </span>
        </div>
      </button>
    </div>
  );
}
