'use client';
import { TrackedEvents } from '@config/index';
import routes from '@config/routes';
import {
  CHECKOUT_START_DATETIME,
  PACKAGE_STORAGE,
  SELECTED_TICKET_STORAGE,
  SOCIAL_LOGIN_REDIRECT,
} from '@config/storageKeys';
import { useCurrencyContext } from '@contexts/currency-context';
import { useBuildPackageBundle } from '@hooks/use-build-package-bundle';
import { useGetShoppingCartById } from '@hooks/use-get-shopping-cart';
import useStorage from '@hooks/useStorage';
import instance from '@instance';
import { LeagueEvent } from '@interfaces/APITravel';
import type { TicketGroup } from '@interfaces/ticketevolution/tikets';
import {
  ExtendedShippedItem,
  OrderTicketTaxQuotaRequest,
  ShipmentFormat,
  ShipmentType,
} from '@interfaces/ticketevolution/tikets';
import { getTicketsTaxQuotes } from '@pages/api/te/tax';
import { useBuildPackagesStore } from '@store/build-package-store';
import { useCheckoutStepsStore } from '@store/checkout-steps-store';
import { useTicketsStore } from '@store/tickets-store';
import { formatCurrencyV2 } from '@utils/format-currency-v2';
import getCookie from '@utils/get-cookie';
import { links, v2Links } from '@utils/navigation/links';
import { showToast } from '@utils/toasts';
import { updateShoppingCart } from '@v2/action/shopping-cart/shopping-cart-action';
import {
  PackageDetails,
  ShoppingCartStepEnum,
  TicketDetails,
} from '@v2/action/shopping-cart/types';
import { Button } from '@v2/ui';
import { PackageType } from '@v2/views/buy';
import { setCookie } from 'cookies-next';
import moment from 'moment-timezone';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import { useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useMemo, useState } from 'react';

type Props = {
  event: LeagueEvent;
  minTicketPrice: number;
  minHotelPrice: number;
};
// Extract price calculation logic
function calculateTotalPrice(params: {
  isBundle: boolean;
  ticketSelected: TicketGroup | null;
  minHotelPrice: number;
  ticketsQuantity: number;
  minTicketPrice: number;
  taxQuota: { tax: number };
}): number {
  const {
    isBundle,
    ticketSelected,
    minHotelPrice,
    ticketsQuantity,
    minTicketPrice,
    taxQuota,
  } = params;

  // Use early returns and explicit conditions
  if (isBundle && !ticketSelected) {
    return minHotelPrice + minTicketPrice;
  }

  if (!ticketSelected && !isBundle) {
    return minTicketPrice;
  }

  const retailPrice = ticketSelected!.retail_price;

  if (!isBundle) {
    return retailPrice * ticketsQuantity + taxQuota.tax;
  }

  return minHotelPrice + retailPrice * ticketsQuantity + taxQuota.tax;
}

function determineShipmentType(
  type: ShipmentFormat,
  event: {
    venue: { time_zone: string };
    occurs_at: string;
  }
): ShipmentType {
  const {
    venue: { time_zone },
    occurs_at,
  } = event;

  const currentDate = moment.tz(
    new Date().toLocaleString('en-US', {
      timeZone: time_zone || 'America/New_York',
    }),
    time_zone
  );

  const eventDate = moment.tz(occurs_at, time_zone);
  const hoursUntilEvent = eventDate.diff(currentDate, 'hours', true);

  switch (type) {
    case 'Flash_seats':
      return 'FlashSeats';
    case 'TM_mobile':
      return 'TMMobile';
    case 'Physical':
      return hoursUntilEvent > 18 ? 'FedEx' : 'LocalPickup';
    default:
      return type;
  }
}

function getTicketPriceData(rate: number, taxes: number, ticketPrice: number) {
  return ticketPrice * rate + taxes;
}
function trackTicketSelection(
  sessionData: Session | null,
  packageData: any,
  ticketDetails: TicketGroup
) {
  const userIp = getCookie('user-ip')?.toString() || 'IP not found';
  const anonymousId =
    getCookie('anonymous-user-id')?.toString() || 'anonymous-user-id not found';
  const user = sessionData?.user;
  const ticketsQuantity = useTicketsStore.getState().ticketsQuantity;
  ticketDetails;

  return instance.server(routes.track, {
    body: JSON.stringify({
      userId: user?.id ? Number(user.id) : undefined,
      anonymousId: user ? undefined : anonymousId,
      trackedEvent: TrackedEvents.TICKET_SELECTED,
      properties: JSON.stringify({
        ip: userIp,
        anonymousId,
        eventName: packageData.eventName!,
        eventDate: packageData.eventDate!,
        ticketCount: ticketsQuantity,
        ticketSection: ticketDetails.section,
        ticketRow: ticketDetails.row,
        retailPrice: ticketDetails.retail_price,
      }),
    }),
    method: 'POST',
  });
}

export function TicketSelectionBottomHeader(props: Props) {
  const { event, minHotelPrice, minTicketPrice } = props;
  const storage = useStorage();
  const [isLoading, setIsLoading] = useState(false);

  const router = useRouter();
  const searchParams = useSearchParams();
  const { data: sessionData } = useSession();

  const ticketsQuantity = useTicketsStore((state) => state.ticketsQuantity);
  const ticketSelected = useTicketsStore((state) => state.ticketSelected);
  const isTicketSelectionConfirmed = useTicketsStore(
    (state) => state.isTicketSelectionConfirmed
  );
  const taxQuota = useTicketsStore((state) => state.taxQuota);

  const eventId = searchParams?.get('eventId');
  const packageType = searchParams!.get('packageType')! as PackageType;

  const cartId = useCheckoutStepsStore((state) => state.cartId);

  const { packageGuests, packageData } = useBuildPackageBundle();
  const isBundle = useBuildPackagesStore((state) => state.isBundle);
  const { priceRate, currency } = useCurrencyContext();

  const { data: shoppingCartData } = useGetShoppingCartById();

  const totalPrice = useMemo(
    () =>
      calculateTotalPrice({
        isBundle,
        ticketSelected,
        minHotelPrice,
        ticketsQuantity,
        minTicketPrice,
        taxQuota,
      }),
    [
      isBundle,
      ticketSelected,
      minHotelPrice,
      ticketsQuantity,
      minTicketPrice,
      taxQuota?.tax,
    ]
  );

  const handleRedirectHotelSelection = useCallback(() => {
    if (packageType === 'packages') {
      router.push(
        `${v2Links.searchHotel}?eventId=${eventId}&packageType=${packageType}`
      );
    }
  }, [packageType, eventId]);

  const onTicketClick = useCallback(
    async ({ tax, tax_signature }: { tax: number; tax_signature: string }) => {
      if (!ticketSelected) return;

      const {
        signature,
        id: ticketGroupId,
        retail_price: retailPrice,
        wholesale_price: wholesalePrice,
        section,
        row,
        format: type,
        public_notes: publicNotes,
      } = ticketSelected;

      const result: ExtendedShippedItem = {
        price: wholesalePrice,
        wholesalePrice,
        retailPrice,
        tax,
        tax_signature: tax_signature || '',
        ticket_group_id: ticketGroupId,
        quantity: ticketsQuantity,
        seat_order: 'consecutive',
        ticket_group_signature: signature,
        stadiumName: event.venue.name || '',
        section,
        row,
        publicNotes: publicNotes || '',
      };

      const selectedTicketPayload = {
        ...result,
        type: determineShipmentType(type as ShipmentFormat, event),
      };

      storage.setItem(
        SELECTED_TICKET_STORAGE,
        JSON.stringify(selectedTicketPayload)
      );

      const totalTicketPrice = retailPrice * ticketsQuantity + taxQuota.tax;
      useBuildPackagesStore.setState({
        selectedTicketPrice: totalTicketPrice,
      });

      setCookie(CHECKOUT_START_DATETIME, new Date().getTime());

      const checkoutUrl = `${links.checkout}?eventId=${eventId}`;
      storage.setItem(SOCIAL_LOGIN_REDIRECT, checkoutUrl);
      const updatedPackageData = {
        ...packageData,
        selected: { ...packageData?.selected, tickets: true },
      };
      storage.setItem(PACKAGE_STORAGE, JSON.stringify(updatedPackageData));

      useCheckoutStepsStore.setState({ ticketSelected: true });
      cartId &&
        updateShoppingCart(cartId, {
          selectedTicketDetails: selectedTicketPayload as TicketDetails,
          packageConfigDetails: {
            ...shoppingCartData?.packageConfigDetails,
            ...updatedPackageData,
            totalPrice: totalTicketPrice,
            isBundle: !!shoppingCartData?.packageConfigDetails?.isBundle,
          } as PackageDetails,
          currentStep: ShoppingCartStepEnum.TICKET_SELECTION,
        });
      if (packageType === 'packages') return handleRedirectHotelSelection();
      router.push(checkoutUrl);
    },
    [ticketSelected, event, eventId, taxQuota]
  );

  const handleConfirmSelection = useCallback(async () => {
    setIsLoading(true);

    try {
      const { id: ticketGroupId } = ticketSelected!;

      const taxQuotaRequest = {
        ticketGroupId,
        quantity: ticketsQuantity,
      } as OrderTicketTaxQuotaRequest;

      const taxQuota = await getTicketsTaxQuotes(taxQuotaRequest);

      const tax = [
        taxQuota?.wholesale?.tax,
        taxQuota?.wholesale?.additional_expense,
        taxQuota?.wholesale?.service_fee,
        taxQuota?.wholesale?.shipping,
      ].reduce((sum, value) => sum + Number(value || 0), 0);

      // Track ticket selection
      await trackTicketSelection(sessionData, packageData, ticketSelected!);

      // Update tickets store
      useTicketsStore.setState({
        taxQuota: {
          tax,
          tax_signature: taxQuota?.tax_signature,
        },
      });

      onTicketClick({ tax, tax_signature: taxQuota?.tax_signature });
    } catch (error) {
      showToast({ message: 'Error confirming ticket selection' });
      setIsLoading(false);
    }
  }, [ticketSelected, ticketsQuantity, packageData, sessionData]);

  const isHotelIncluded = packageType === 'packages';
  const bottomTotalPriceLabel = isHotelIncluded
    ? 'Include taxes, hotel & tickets'
    : 'Include taxes & tickets';

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 w-full bg-gray-900">
      <div className="flex flex-col items-start justify-between gap-y-4 px-4 py-4 md:mx-auto md:max-w-[1400px] md:flex-row md:items-center md:px-20 md:py-5">
        <div className="flex items-start justify-between gap-x-3 md:justify-start">
          <div>
            <div className="flex items-start gap-x-3 md:items-center">
              <h5 className="text-lg font-bold text-white">
                Estimated{' '}
                {formatCurrencyV2(
                  totalPrice / ticketsQuantity,
                  priceRate,
                  currency,
                  true,
                  false
                )}{' '}
                per person
              </h5>
            </div>
            <span className="text-sm font-medium text-gray-400">
              {formatCurrencyV2(totalPrice, priceRate, currency, true, false)}{' '}
              in total. {bottomTotalPriceLabel}
            </span>
          </div>
        </div>

        {packageType === 'packages' && !isTicketSelectionConfirmed ? (
          <Button
            variant="ghost"
            className="w-full border border-gray-700 bg-transparent text-white md:w-max"
            onClick={() => {
              handleRedirectHotelSelection();
              useCheckoutStepsStore.setState({ ticketSelected: false });
              useBuildPackagesStore.setState({ selectedTicketPrice: 0 });
              storage.removeItem(SELECTED_TICKET_STORAGE);
            }}
          >
            {"I don't need tickets"}
          </Button>
        ) : (
          <Button
            isLoading={isLoading}
            disabled={packageType === 'tickets' && !isTicketSelectionConfirmed}
            onClick={handleConfirmSelection}
            className="w-full md:w-max"
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
}
